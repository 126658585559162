export default async function getMenuData() {
  return [
    {
      title: 'Home',
      key: 'dashboardBeta',
      icon: 'fe fe-home',
      url: '/dashboard/beta',
    },
    {
      title: 'Management',
      key: 'management',
      icon: 'fe fe-folder',
      children: [
        {
          title: 'Weekly Invoice Time Report',
          key: 'weeklyinvoicetime',
          url: '/management/weeklyinvoicetime',
        },
        {
          title: 'Invoice Time Comprehensive',
          key: 'invoicetime',
          url: '/management/invoicetime',
        },
        {
          title: 'Contact Link',
          key: 'contactlink',
          url: '/management/contactlink',
        },
      ],
    },   
  ]
}
