/* eslint-disable */
import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAyGv1ZEwiM1ZNAm98Z_LKQzcGQ9FUmuiI',
  authDomain: 'growthlogic-1.firebaseapp.com',
  databaseURL: 'https://growthlogic-1.firebaseio.com',
  projectId: 'growthlogic-1',
  storageBucket: 'growthlogic-1.appspot.com',
  messagingSenderId: '234233208774',
}

firebase.initializeApp(firebaseConfig)
export const firebaseAuth = firebase.auth()

export async function login(email, password) {
  return firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function register(email, password, name) {
  /*return firebaseAuth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user 
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })*/
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {
          if (user) {
            /*
            const userFields = await firebaseDatabase
              .ref('users')
              .child(user.uid)
              .once('value')
              .then(snapshot => {
                return snapshot.val()
              })
            const mergedUser = Object.assign(user, {
              id: user.uid,
              name: userFields.name,
              role: userFields.role,
              avatar: user.photoUrl,
            })
            return mergedUser
            */
          }
          console.log(user);
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth)
}

export async function logout() {
  return firebaseAuth.signOut().then(() => true)
}

export function tokenId() {
  return new Promise(resolve => {
    firebase.auth().currentUser.getIdToken(/* forceRefresh true */).then(function(idToken) {
      resolve(idToken);
    })
  });
}